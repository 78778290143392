import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo.png";
import "./style.scss";
 
import config from "../../config";
import AlertMessage from "../Alert/AlertMessage";

const footerLinks = [
  {
    title: "Quick Links",
    menus: [
      { name: "Home", route: "#" },
      { name: "About Us", route: "AboutUs" },
      { name: "Our Team", route: "OurTeam" },
      { name: "Contact Us", route: "Contact" },
      { name: "Family Law", route: "familyLaw" },
      { name: "Civil Litigation", route: "CivilLitigation" },
     
    ],
  },
  {
    title: "",
    menus: [
      { name: "Corporate/Business Law", route: "BusinessLaw" },
      { name: "Criminal Law", route: "CriminalLaw" },
      { name: "Immigration Law", route: "ImmigrationLaw" },
      { name: "Real Estate Law", route: "RealEstateLaw" },
      { name: "Wills & Estate", route: "WillsEstate" },
    ],
  },
  {
    title: "Contact Us",
    menus: [
      { name: config.Address.Mississauga.caption },
      { name: config.Address.Mississauga.street },
      { name: config.Address.Mississauga.city },
      { name: config.Address.Mississauga.phone },
      { name: config.Address.Mississauga.email },
    ],
  },
  {
    title: "",
    menus: [
      { name: config.Address.Cambridge.caption },
      { name: config.Address.Cambridge.street },
      { name: config.Address.Cambridge.city },
      { name: config.Address.Cambridge.phone },
      { name: config.Address.Cambridge.email },
    ],
  },
];

const FooterArea = () => {
  const [showAlert, setShowAlert] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };
  const handleShowAlert = () => {
    setShowAlert(true); // Display the alert
  };

  const handleCloseAlert = () => {
    setShowAlert(false); // Close the alert
  };

  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
                <p></p>
              </div>
            </div>
          </div>
          <div className="row">
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                <div className="footerWrap">
                <h3 dangerouslySetInnerHTML={{ __html: menu.title || "&nbsp;" }}></h3>
                 
                  <ul>
                    {menu.menus.map((item, index) => (
                      <li key={index}>
                        {item.route ? (
                          <Link to={`/${item.route}`}>{item.name}</Link>
                        ) : (
                          item.name
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-6 col-sm-10 col-12">
                <span>© 2022-2025 Ardent Law Firm. All rights reserved</span>
                <span onClick={handleShowAlert} style={{ cursor: "pointer" }}>
                  Disclaimer
                </span>
                {showAlert && (
                  <AlertMessage
                    message={`The content on this website, including this Privacy Policy, is for informational purposes only and does not constitute legal advice. Accessing this website or submitting information <b>does not</b> "establish a lawyer-client relationship". If you require legal assistance, please contact us directly.`}
                    onClose={handleCloseAlert}
                  />
                )}
              </div>
              <div className="col-md-2 col-sm-10 col-12">
                <ul className="socialListFooter">
                  <li>
                    <a
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/profile.php?id=100076569611115"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/ardentlawfirm/"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/ardent-law-professional-corporation/about/"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-10 col-12 privacyPolicy">
               
             <ul>
              <li>
              <Link
                    to={`/PrivacyPolicy`}
                   
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
              
              </li>
             </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterArea;
