import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
 
import FooterArea from '../../../components/FooterArea'
// images
import familyLaw from '../../../images/common/FamilyLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'
import config from '../../../config'
import './style.scss'
const aboutText =config.WillsEstate;
// const aboutText = [
//     { text: '' },
//     { text: 'At Ardent Law firm, we provide Will & Estate services to help our esteemed client in Ontario.  Whether you want to prepare your own Will or our legal services in the property division, we are your go-to Wills & Real Estate Lawyer Ontario.  We make the whole process extremely easy & convenient for family members in such cases.  ' },
//     { text: 'Our team offers pragmatic advice on specific complex planning issues like the Creation of Trust, Guardianship or Succession of Business.  In short, no matter what your specific needs are or what particular services you want, our team is here to help you through any complexities related to Will & Real Estate.' },
//     { texth: 'What services do we offer?' },
//     { textc: '•	Wills & Codicils' },
//     { textc: '•	Powers of Attorney for Property and Personal Care' },
//     { textc: '•	Guardianship' },
//     { textc: '•	Estate & Tax Planning' },
//     { textc: '•	Estate Freezes' },
//     { textc: '•	Estate Distribution and Administration' },
// ]

const services = [
    {
        icon: config.PageMenu.FamilyLaw.icon,
        title: config.PageMenu.FamilyLaw.title,
        content: config.PageMenu.FamilyLaw.content,
        link:config.PageMenu.FamilyLaw.link
    },
    {
        icon: config.PageMenu.CivilLitigation.icon,
        title: config.PageMenu.CivilLitigation.title,
        content: config.PageMenu.CivilLitigation.content,
        link:config.PageMenu.CivilLitigation.link
    },
    {
        icon: config.PageMenu.BusinessLaw.icon,
        title: config.PageMenu.BusinessLaw.title,
        content: config.PageMenu.BusinessLaw.content,
        link:config.PageMenu.BusinessLaw.link
    },
    {
        icon: config.PageMenu.CriminalLaw.icon,
        title: config.PageMenu.CriminalLaw.title,
        content: config.PageMenu.CriminalLaw.content,
        link:config.PageMenu.CriminalLaw.link
    },
    {
        icon: config.PageMenu.Immigration.icon,
        title: config.PageMenu.Immigration.title,
        content: config.PageMenu.Immigration.content,
        link:config.PageMenu.Immigration.link
    },
    {
        icon: config.PageMenu.RealEstateLaw.icon,
        title: config.PageMenu.RealEstateLaw.title,
        content: config.PageMenu.RealEstateLaw.content,
        link:config.PageMenu.RealEstateLaw.link
    },
 
  
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Wills & Estate'}
]

const WillsEstate = () => {
    return (
        <Fragment>
            <header className="headerArea">
            <HeaderBotton className="headerBottomArea " />
                <HeaderTop className="headerTop" />
                {/* <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" /> */}
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Wills & Estate"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Wills & Estate"
                images={familyLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default WillsEstate