import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import familyLaw from '../../../images/common/FamilyLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText =config.CivilLitigation;
// const aboutText = [
//     { text: '' },
//     { text: 'At Ardent Law firm, we offer professional & tailored civil litigation services to our clients according to their needs and goals. Our goal is to dedicatedly work with our clients & develop strategies that will help them get their desired outcomes. We don\'t back down from the fight for our clients at multiple levels, including tribunals & Provincial/Federal commissions alike. We care about our valued Clients & everything legally possible to help them get the best results in Civil litigation lawsuits. At Ardent Law Firm, we understand how much you rely on your civil litigation lawyer. So we take it as our moral responsibility to offer you the best services & guidance at every stage of your litigation process. ' },
//     { texth: 'What services do we offer?' },
//     { textc: '• Breach of Contract' },
//     { textc: '•	Tort Litigation' },
//     { textc: '•	Professional Negligence' },
//     { textc: '•	Appeals and Judicial Reviews' },
//     { textc: '•	Partnership and Business Disputes' },
//     { textc: '•	Injunctions and Ex Parte Motions' },
//     { textc: '•	Estate Litigation' },
//     { textc: '•	Disability Benefits' },
//     { textc: '•	Real Estate Litigation and Latent Defect Claims' },
//     { textc: '•	Breach of Charter of Rights and Freedoms' },
//     { textc: '•	Defamation' },
//     { textc: '•	Insurance disputes' },
//     { textc: '•	Collection actions' },
// ]

const services = [
    {
        icon: config.PageMenu.FamilyLaw.icon,
        title: config.PageMenu.FamilyLaw.title,
        content: config.PageMenu.FamilyLaw.content,
        link:config.PageMenu.FamilyLaw.link
    },
 
    {
        icon: config.PageMenu.BusinessLaw.icon,
        title: config.PageMenu.BusinessLaw.title,
        content: config.PageMenu.BusinessLaw.content,
        link:config.PageMenu.BusinessLaw.link
    },
    {
        icon: config.PageMenu.CriminalLaw.icon,
        title: config.PageMenu.CriminalLaw.title,
        content: config.PageMenu.CriminalLaw.content,
        link:config.PageMenu.CriminalLaw.link
    },
    {
        icon: config.PageMenu.Immigration.icon,
        title: config.PageMenu.Immigration.title,
        content: config.PageMenu.Immigration.content,
        link:config.PageMenu.Immigration.link
    },
    {
        icon: config.PageMenu.RealEstateLaw.icon,
        title: config.PageMenu.RealEstateLaw.title,
        content: config.PageMenu.RealEstateLaw.content,
        link:config.PageMenu.RealEstateLaw.link
    },
    {
        icon: config.PageMenu.WillsEstate.icon,
        title: config.PageMenu.WillsEstate.title,
        content: config.PageMenu.WillsEstate.content,
        link:config.PageMenu.WillsEstate.link
    },
  
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Civil Litigation'}
]

const FamilyLaw = () => {
    return (
        <Fragment>
            <header className="headerArea">
            <HeaderBotton className="headerBottomArea " />
                <HeaderTop className="headerTop" />
                {/* <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" /> */}
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Civil Litigation"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Civil Litigation"
                images={familyLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default FamilyLaw