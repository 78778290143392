import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { NavLink, Link } from "react-router-dom";
import "react-modal-video/scss/modal-video.scss";
import "./style.scss";

const About = ({
  subTitle,
  title,
  videoId,
  className,
  signature,
  pragraphs,
  images,
  orderLast,
}) => {
  const [video, setVideo] = useState(false);
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div
            className={orderLast ? `${orderLast} col col-lg-6` : "col col-lg-6"}
          >
            <div className="aboutImageWrap">
              <img src={images} alt="" />
              {/* {videoId && (
                <i onClick={() => setVideo(true)} className="fa fa-play"></i>
              )}
              <ModalVideo
                channel="youtube"
                isOpen={video}
                videoId={videoId}
                onClose={() => () => setVideo(false)}
              /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="aboutContent">
              {subTitle && <span>{subTitle}</span>}
                
              {pragraphs.map((pragraph, i) => (
                <p key={i}>{pragraph.text}</p>
              ))}
              {pragraphs.map((pragraph, i) => (
    pragraph.texth === "Consult now" ? (
        <NavLink key={i} to="/contact">
           <span key={i}>{pragraph.texth}</span>
        </NavLink>
    ) : (
        <span key={i}>{pragraph.texth}</span>
    )
))}
                {pragraphs.map((pragraph, i) => (
                <p key={i}>{pragraph.textc}</p>
              ))}
            
            {/*<div className="btnStyle">
                <Link to="/">More About Us..</Link>
                </div> */}
              {/* <h2>{title}</h2>  under subtitle {signature && <div className="signature">
                                <img src={signature} alt="" />
                            </div>} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
