import React from "react";
import "./style.scss";
import { text } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
// Sample data array for teams
const teams = [
  {
    id: 1,
    name: "Gurkanwarpal Singh Rehal",
    profession : "Lawyer",
    link:"GurkanwarpalSinghRehal",
    tag:"Your Trusted Real Estate & Immigration Lawyer",
    desc:"Gurkanwarpal is an experienced real estate and immigration lawyer, dual-licensed in Canada and India. He handles real estate transactions, including purchase, sale, and refinance of properties, ensuring clients receive clear and effective legal guidance.Gurkanwarpal began working on immigration matters in 2019 while employed in the legal field. After obtaining his Canadian license in 2021, he expanded his practice to real estate law while continuing to assist clients with complex immigration cases. His background in immigration law has helped him successfully handle Judicial Review applications, offering strategic solutions for challenging cases.Beyond his legal practice, Gurkanwarpal is an avid traveler and cyclist, enjoys trying new recipes, and values spending quality time with his family and dogs. ",
    emailID :"grehal@ardentlawfirm.com",
    contact : "+1 226-995-0095",
    image: require("../../images/our-team/GurkanwarpalBig.png"),
    imageSmall: require("../../images/our-team/Gurkanwarpal.png"),
    info: [
          { level: "Closing - Sale of property", text: "" },
       { level: "Closing - Purchase of property", text: "" },
       { level: "Refinance of property", text: "" },
       { level: "Commercial Property Sale and Purchase", text: "" },
       { level: "Wills & Power of Attorneys", text: "" },
       { level: "Judicial review application", text: "" },
       { level: "Refugee Claims", text: "" },
       { level: "LMIA", text: "" },
    ],
  },
  {
    id: 2,
    name: "Karanvir Sidhu",
    profession : "Lawyer",
    link:"KaranvirSidhu",
   
    emailID :"Ksidhu@ardentlawfirm.com",
    contact : "+1 647-877-3847",
    image: require("../../images/our-team/KaranvirBig.png"),
    imageSmall: require("../../images/our-team/Karanvir.png"),
    info: [
          { level: "Criminal Law", text: "" },
          { level: "Family Law", text: "" },
    ],
  },
  {
    id: 3,
    name: "Afreen Sharma",
    profession : "Lawyer",
    link:"AfreenSharma",
    tag:"Dedicated immigration lawyer",
    desc:"Afreen is a dedicated immigration lawyer, licensed in both India and Canada, with extensive experience in immigration law. She has been working in the field since 2017, initially gaining valuable experience as an immigration case manager before becoming a licensed lawyer in 2022. She completed the Law Practice Program at Ryerson University, which provided her with the practical skills and knowledge needed to succeed in her legal career. Along with her focus on immigration law, she has developed an interest in business law, aiming to broaden her expertise and better serve clients with diverse legal needs. Outside of her legal practice, Afreen is an animal lover, enjoys reading, and has a passion for dancing. She also loves traveling, which allows her to explore new cultures and gain fresh perspectives. Her commitment to both personal and professional growth is evident in every aspect of her life. ",
    emailID :"afreen@ardentlawfirm.com",
    contact : "+1 519-410-1010",
    image: require("../../images/our-team/AfreenBig.png"),
    imageSmall: require("../../images/our-team/Afreen.png"),
    info: [
          { level: "Visitors", text: "" },
       { level: "Workers", text: "" },
       { level: "Inadmissibility Cases", text: "" },
       { level: "Immigration Appeal", text: "" },
       { level: "Federal Court", text: "" },
       { level: "Business Law", text: "" },
       { level: "Investment category", text: "" },
       { level: "Citizenship", text: "" },
       { level: "H & C application", text: "" },
       { level: "Family Sponsorship", text: "" },
       { level: "Express entry & PNP’s", text: "" },
    ],
    contents: [
      "Bachelor of Law, University of Toronto",
      "Master of Business Administration, Harvard University",
      "Certified in Corporate Law",
    ],
    socialLinks: [
      { platform: "facebook", url: "#" },
      { platform: "twitter", url: "#" },
      { platform: "linkedin", url: "#" },
      { platform: "google-plus", url: "#" },
    ],
    languages: "French (fluent), English (fluent), Greek, Chinese",
    experience: `There are many variations of passages of Lorem Ipsum available,
    but the majority have suffered alteration in some form, by injected humour,
    or randomised words which don't look even slightly believable.`,
  },
 
  {
    id: 4,
    name: "Gurvir Sidhu",
    profession : "Lawyer",
    link:"GurvirSidhu",
    tag:"Professional Lawyer",
    desc:"Gurvir is a dedicated and driven professional with a strong academic background, holding a Bachelor of Commerce degree followed by his law degree from the University of Leicester. As a lawyer, Gurvir combines a deep understanding of business principles with legal expertise, offering a unique approach to problem-solving and strategic thinking. Outside of work, Gurvir is passionate about motorcycles, cycling, and hiking—activities that reflect a love for adventure and personal challenges.  With a commitment to continuous learning and improvement, Gurvir brings energy, enthusiasm, and determination to every aspect of life, whether in the courtroom, on the trail, or on the road. ",
    emailID :"gsidhu@ardentlawfirm.com",
    contact : "+1 647-936-0607",
    image: require("../../images/our-team/GurvirBig.png"),
    imageSmall: require("../../images/our-team/Gurvir.png"),
    info: [
          { level: "Civil Litigation", text: "" },
       { level: "Small claims Court", text: "" },
       { level: "Personal Injury", text: "" },
       { level: "Employment Law", text: "" },
    ],
  },
  {
    id:5,
    name: "Arvish Bindal",
    link:"ArvishBindal",
    tag:"Office co-ordinator",
    profession : "Office co-ordinator",
    desc:"Arvish Bindal is a dedicated legal professional with a strong commitment to justice, continuous growth, and learning. He pursued law in India, where he is also licensed to practice. To enhance his expertise in conflict resolution, Arvish furthered his education by studying Alternative Dispute Resolution at Humber College, Toronto. Having successfully completed the NCA examinations in 2024, he is now preparing for the Bar Examination in Canada in 2025. Outside of his professional pursuits, Arvish enjoys exploring nature, reading about history and economics, and engaging in activities that foster both personal and professional development. A fun fact about Arvish is that he enjoys cooking, finding it a rewarding way to relax and express his creativity. ",

    image: require("../../images/our-team/ArvishBig.png"),
    
    imageSmall: require("../../images/our-team/Arvish.png"),
  },
  {
    id: 6,
    name: "Anmoldeep Kaur",
    link:"AnmoldeepKaur",
    tag:"Office Administrator",
    profession:"Office Administrator",
    desc:"Anmoldeep Kaur is the Office Administrator at Ardent Law Firm. She plays a key role in maintaining smooth office operations while providing essential support to clients and assisting the legal team.Anmoldeep is a foreign-trained lawyer from India and an NCA-qualified Student-at-Law. She earned her Bachelor of Laws (LL.B.) degree in India before moving to Canada to further her legal career. Prior to completing her NCA exams, she graduated with honours in Paralegal Studies from Humber College, gaining valuable knowledge of the Canadian legal system and building a strong foundation for her future legal career. ",
    
    image: require("../../images/our-team/AnmoldeepBig.png"),
    imageSmall: require("../../images/our-team/Anmoldeep.png"),
    
  },
 
];

const TeamMember = (props) => {
  return (

    props.teamId === -1 ? <div className="singleTeamArea">
       
    <div className="container">
      {teams.map((team, id) => (
        <Link to={`/${team.link}`}>
        <div
          key={team.id}
          className={`teamList-container ${id % 2 === 0 ? 'even' : 'odd'}`}
        >
          <div className="teamList-image-text-wrapper">
            <div className="teamList-image">
              <img src={team.imageSmall} alt={team.name} />
            </div>
            <div className="teamList-text">
              <h4><b>{team.name}</b></h4>
              {team.profession && <h6>({team.profession})</h6>}
            </div>
          </div>
        </div>
        </Link>
      ))}
   
    </div>
    
  </div>
    :


    <div className="singleTeamArea">
      <div className="container">
      {teams.filter((team) => team.id === props.teamId).map((team) =>  (
          <div key={team.id} className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="singleTeamImgWrap">
                    <div className="singleTeamImg">
                      <img src={team.image} alt={team.name} />
                      
                    </div>
                    <span style={{ textAlign: 'justify', display: 'block' }}> {team.desc}</span>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="singleTeamContent">
                  <div className="text-center">
              <h4><b>{team.name}</b>
              {team.profession && <h6>({team.profession})</h6>}
              </h4>
              
</div>
                    <ul className="teamMembarInfo">
                    {team.emailID && (
  <div className="row">
    <div className="col-3">
      <b>Email Id</b>
    </div>
    <div className="col-9">
      {team.emailID}
    </div>
  </div>
)}

{team.contact && (
  <div className="row">
    <div className="col-3">
      <b>Contact No.</b>
    </div>
    <div className="col-9">
      {team.contact}
    </div>
  </div>
)}
                      <hr></hr>
                      
                      {team.info && team.info.length > 0 && (
  <li>
    <span>Area of Service:</span>
    <div className="row">
      {team.info.map((info, i) => (
        <li key={i} className="col-lg-12 col-md-12 col-12">
          {info.level}
        </li>
      ))}
    </div>
  </li>
)}
 
                    </ul>
                    {/* <ul className="socialShare">
                      {team.socialLinks.map((link, i) => (
                        <li key={i}>
                          <a href={link.url}>
                            <i className={`fa fa-${link.platform}`}></i>
                          </a>
                        </li>
                      ))}
                    </ul> */}
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="singleTeamInfo">
                    <h3>Personal Experience</h3>
                    <p>{team.experience}</p>
                    <h5>Education</h5>
                    <ul>
                      {team.contents.map((content, index) => (
                        <li key={index}>{content}</li>
                      ))}
                    </ul>
                    <h5>Language</h5>
                    <span>{team.languages}</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto',  }}>
  <Link 
    to={`/ourteam`} 
    style={{
      padding: '15px 30px',
      fontSize: '24px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: 'white',
      backgroundColor: 'grey',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease'
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = '#0056b3'}
    onMouseLeave={(e) => e.target.style.backgroundColor = 'grey'}
  >
    Our Team
  </Link>
</div>
    </div>
  );
};

export default TeamMember;
