import { Component } from "react";
import { useLocation } from "react-router-dom";

class ScrollToToper extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

// HOC to pass the `location` prop to ScrollToToper
export default function ScrollToTopWrapper(props) {
  const location = useLocation();
  return <ScrollToToper {...props} location={location} />;
}
