import React from "react";
import "./style.scss";
import config from "../../config"
const HeaderTop = (props) => {
  return (<div></div>)
  // return (
  //   <div className={props.className}>
  //     <div className="container">
  //       <div className="headerTopMainWrapper">
  //         <div className="row">

  //           <div className="col-md-3 col-sm-12 col-12 col-lg-5">
  //             <ul className="d-flex accountLoginArea">
  //               <li>
  //                 <i className="fa fa-map-marker" aria-hidden="true"></i>{config.Address.Mississauga.street+","+config.Address.Mississauga.city}
  //               </li>
            
  //             </ul>
  //             <ul className="d-flex accountLoginArea">
  //               <li>
  //                 <i className="fa fa-map-marker" aria-hidden="true"></i>{config.Address.Cambridge.street+","+config.Address.Cambridge.city}
  //               </li>
            
  //             </ul>
  //           </div>
            
  //           <div className="col-md-6 col-sm-12 col-12 col-lg-5">
  //             <ul className="headerContact">
  //               <li>
  //                 <i className="fa fa-phone"></i> {config.Address.Mississauga.phoneInternational}
  //               </li>
  //               <li>
  //                 <i className="fa fa-clock-o"></i> 9AM - 6PM
  //               </li>
  //             </ul>
  //             <ul className="headerContact">
  //               <li>
  //                 <i className="fa fa-phone"></i> {config.Address.Cambridge.phoneInternational}
  //               </li>
  //               <li>
  //                 <i className="fa fa-clock-o"></i> 9AM - 6PM
  //               </li>
  //             </ul>
  //           </div>
  //           <div className="col-lg-2 col-md-3 col-sm-12">
  //             <div className="btnStyle  text-right">
  //               <a className="btnStylelower" href="#">{config.Address.Mississauga.emailId}</a>
  //             </div>
  //             <div className="btnStyle  text-right">
  //               <a className="btnStylelower" href="#">{config.Address.Cambridge.emailId}</a>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
export default HeaderTop;
