import React from "react";
import Form from "../Form";
import "./style.scss";
import config from "../../config";
const ContactArea = ({ className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="row visible_row">
          <div className="col-lg-5 col-12">
            <div className="contactInfo">
              <span></span>
              <h2>Book Appointment</h2>
              <h6>
                Call us at {config.Address.Mississauga.phoneInternational} ,<br/> {config.Address.Cambridge.phoneInternational}  or fill out the
                form.
              </h6>
              <p></p>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactArea;
