// AlertMessage.js
import React from "react";
import "./AlertMessage.scss";  // Make sure you have the CSS file

const AlertMessage = ({ message, onClose }) => {
  return (
    <div className="alert-message">
      <div className="alert-content">
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
        
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default AlertMessage;
