import React, { Component } from "react";

import Joi from "joi-browser";
 
import "./style.scss";
import { createAPIEndpoint, ENDPOINTS } from  "../../api";
 
import {randomNumberInRange} from "../../containers/Pages/global.js"
import MessageBox from "../MsgBox/MessageBox";
function DisplayMessage(msg){
 

  MessageBox.open({
      title: "Ardent Law Firm",
      content: <p>{msg}</p>,
      buttons: [
        { name: "Ok", handler: () => "Ok" },
        // { name: "Nevermind", handler: () => "nope" }
      ]
    });
    
  }
class Form extends Component {
  state = {
    branch:"",
    name: "",
    mobile: "",
    email: "",
    address: "",
    description: "",
    rndNumber:randomNumberInRange(100000,999999)+"",
    rndNumberConfirm:"",
    error: {},
  };
 
 
    
  schema = {
   
    
    rndNumberConfirm: Joi.string()
    .required()
    
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          default:
            err.message = "Empty";
            break;
        }
      });
      return errors;
    }),
         

    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "email mast be A Valid Email";
              break;
            default:
              err.message = "email can not be empity";
              break;
          }
        });
        return errors;
      }),
    mobile: Joi.string()
      .required()
      
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "phone can not be Empity";
              break;
          }
        });
        return errors;
      }),
    name: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "name can not be Empity";
              break;
          }
        });
        return errors;
      }),
    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "description can not be Empity";
              break;
          }
        });
        return errors;
      }),
    address: Joi.string(),
    branch: Joi.string(),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      description: this.state.description,
     
      rndNumberConfirm:this.state.rndNumberConfirm,
    };
    const { error } = Joi.validate(form, this.schema, options);
    

     var errors = {};
      if (error)
   
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
     
    }   
     
    if(!(this.state.rndNumber.toString() ===form.rndNumberConfirm.toString()) ){
      errors["rndNumberConfirm"]="Verificaton code mismatch!!";
      this.state.rndNumber=randomNumberInRange(100000,999999)+"";
    }
      
  else
  {
    if (!error) return null;
  }

 
    return errors;
    

  };
 
  submitHandler = (event) => {
    event.preventDefault();
    // console.log(
    //   "Name" + "=" + this.state.name,
    //   "Phone" + "=" + this.state.mobile,
    //   "Email" + "=" + this.state.email,
    //   "Address" + "=" + this.state.address,
    //   "Description" + "=" + this.state.description
    // );
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
      console.log(error);
      return;
      // console.log(
      //   "Name" + "=" + error,
      //   "Phone" + "=" + this.state.mobile,
      //   "Email" + "=" + this.state.email,
      //   "Address" + "=" + this.state.address,
      //   "Description" + "=" + this.state.description
      // );
     } 
    //  else {
    //   this.setState({
    //     name: "",
    //     mobile: "",
    //     email: "",
    //     address: "",
    //     description: "",
    //     rndNumber:randomNumberInRange(100000,999999)+"",
    //     rndNumberConfirm:""
    //   });
    // }
      createAPIEndpoint(ENDPOINTS.email)
      .post(this.state)
      .then(res => {
        DisplayMessage(JSON.stringify(res.data));
          this.setState({
            branch:"",
        name: "",
        mobile: "",
        email: "",
        address: "",
        description: "",
        rndNumber:randomNumberInRange(100000,999999)+"",
        rndNumberConfirm:""
      });
      })
      .catch(err => {   DisplayMessage("Please try after some time.");
      // this.setState({
      //   name: "",
      //   mobile: "",
      //   email: "",
      //   address: "",
      //   description: "",
      //   rndNumber:randomNumberInRange(100000,999999)+"",
      //   rndNumberConfirm:""
      // });
    });
  
    }
    
  render() {
    const options = [
      { level: "Family Law", value: "Family Law" },
      { level: "Civil Litigation", value: "Civil Litigation" },
      { level: "Criminal Law", value: "Criminal Law" },
      { level: "Corporate/Business Law", value: "Business Law" },
      { level: "Immigration Law", value: "Immigration Law" },
      { level: "Real Estate Law", value: "Real Estate Law" },
      { level: "Wills/Power of Attorney", value: "Wills Power of Attorney" },
      { level: "Others", value: "Others" },
    ];
    const optionsBranch = [
      { level: "Mississauga", value: "Mississauga" },
      { level: "Cambridge", value: "Cambridge" },
    
    ];
    return (
      <form onSubmit={this.submitHandler} className="contactForm">
        <div className="row">
        <div className="col-sm-12 col-12">
        <div className="form-input">
        {this.props.black ? (
         <div style={{ color: 'black' }}>Select Branch</div>):
        (  <div style={{ color: 'white' }}>Select Branch</div>)
        }
          </div>
        <div className="formInput">
        <select
                  value={this.state.branch}
                  className="form-control"
                  onChange={this.changeHandler}
                   name="branch"
                >
                  {optionsBranch.map((optionsBranch) => (
                    <option key={optionsBranch.value} value={optionsBranch.value}>
                      {optionsBranch.level}
                    </option>
                  ))}
                </select>
                </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input
                placeholder="Your Name"
                value={this.state.name}
                name="name"
                onChange={this.changeHandler}
                className="form-control"
                type="text"
              />
              {this.state.error.name && <p>{this.state.error.name}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input
                placeholder="Phone"
                value={this.state.mobile}
                name="mobile"
                onChange={this.changeHandler}
                className="form-control"
                type="phone"
              />
              {this.state.error.mobile && <p>{this.state.error.mobile}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input
                placeholder="Email"
                value={this.state.email}
                name="email"
                onChange={this.changeHandler}
                className="form-control"
                type="email"
              />
              {this.state.error.email && <p>{this.state.error.email}</p>}
            
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              {this.props.addressInfo ? (
                <div className="formInput">
                  <input
                    placeholder="Address"
                    value={this.state.address}
                    name="address"
                    onChange={this.changeHandler}
                    className="form-control"
                    type="address"
                  />
                </div>
              ) : (
                <select
                  value={this.state.address}
                  className="form-control"
                  onChange={this.changeHandler}
                  name="address"
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.level}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <textarea
                className="form-control"
                value={this.state.description}
                onChange={this.changeHandler}
                placeholder="Case Description..."
                name="description"
              />
              {this.state.error.description && (
                <p>{this.state.error.description}</p>
              )}
            </div>
          </div>
          <div className="col-6">
              <span  name="rndNumber" value={this.state.rndNumber} className="rndnumber">{this.state.rndNumber}</span>
                <div className="formInput">
                  <input
                    placeholder="Verify Number"
                    value={this.state.rndNumberConfirm}
                    name="rndNumberConfirm"
                    onChange={this.changeHandler}
                    className="form-control"
                    type="text"
                  />
           
           {this.state.error.rndNumberConfirm && (
                <p>{this.state.error.rndNumberConfirm}</p>
              )}
                </div>
          </div>
          <div className="col-12">
            <button type="submit">Submit</button>
          </div>
        </div>
        <div id="app-message-box" />
      </form>
    );
  }
}
export default Form;
