import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import familyLaw from '../../../images/common/FamilyLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText =config.CriminalLaw;
// const aboutText = [
//     { text: '' },
//     { text: 'We are a team of professional & specialized criminal lawyers with years of experience. When it comes to legal advice, we offer precise, reliable & the best guidance to our clients & help secure their future. We focus on solving cases as quickly & as efficiently we can.' },
//     { text: 'We strongly believe in the principle of Canadian law, "Innocent until Proven Guilty". And to uphold this principle, we go a step beyond helping our clients out.' },
//     { text: 'Regardless of the case, we approach it with zero compromise & unflinching dedication to bringing out the best possible outcome for our clients. ' },
//     { text: 'We fully understand how shocking & scary it is when being charged criminally. However, a small mistake on your lawyer\'s end & your entire life can be ruined. So we deploy all our resources to make sure you get the most favourable outcome from the entire case.' },
//     { text: 'No matter how complex a criminal case is, we have a team of professionals with specialization in every criminal law. Whether its trial or appeal, we have the best team to handle all types of cases. ' },
//     { text: 'When it comes to defending our clients, we act fearlessly & vehemently to protect our clients\' interests. We believe in zero compromises & deliver the best outcomes from every case. We pay absolute attention to each case to ensure our clients\' self–interests are preserved.' },
//     { text: 'Our team specializes in almost every type of Criminal charge brought up against our client. ' },
//     { text: '' },
//     { text: 'Here is the list:' },
//     { text: '' },
//     { text: '•	Assault' },
//     { text: '•	Domestic Assault' },
//     { text: '•	Aggravated Assault and Assault with a Weapon' },
//     { text: '•	Uttering Threats' },
//     { text: '•	Criminal Harassment' },
//     { text: '•	Sexual Assault' },
//     { text: '•	Fraud and Credit Card Possession' },
//     { text: '•	Mischief Under $5000 and Mischief Over $5000' },
//     { text: '•	Shoplifting / Theft Under $5000' },
//     { text: '•	Dangerous Driving' },
//     { text: '•	Fail to Comply with Bail' },
//     { text: '•	Drug Possession and Drug Importing' },
//     { text: '•	Drug Trafficking' },
//     { text: '•	Homicides: Murder and Manslaughter' },
//     { text: '•	Weapons Possession' },
//     { text: '•	Bail Hearings and Bail Detention Reviews' },
//     { text: '•	Extradition Hearings' },
//     { text: '•	Firearms Possession and Other Gun Charges' },
//     { text: '•	Young Offenders' },
//     { text: '•	Victim Support and Assistance' },
 
// ]

const services = [
    {
        icon: config.PageMenu.FamilyLaw.icon,
        title: config.PageMenu.FamilyLaw.title,
        content: config.PageMenu.FamilyLaw.content,
        link:config.PageMenu.FamilyLaw.link
    },
    {
        icon: config.PageMenu.CivilLitigation.icon,
        title: config.PageMenu.CivilLitigation.title,
        content: config.PageMenu.CivilLitigation.content,
        link:config.PageMenu.CivilLitigation.link
    },
    {
        icon: config.PageMenu.BusinessLaw.icon,
        title: config.PageMenu.BusinessLaw.title,
        content: config.PageMenu.BusinessLaw.content,
        link:config.PageMenu.BusinessLaw.link
    },
 
    {
        icon: config.PageMenu.Immigration.icon,
        title: config.PageMenu.Immigration.title,
        content: config.PageMenu.Immigration.content,
        link:config.PageMenu.Immigration.link
    },
    {
        icon: config.PageMenu.RealEstateLaw.icon,
        title: config.PageMenu.RealEstateLaw.title,
        content: config.PageMenu.RealEstateLaw.content,
        link:config.PageMenu.RealEstateLaw.link
    },
    {
        icon: config.PageMenu.WillsEstate.icon,
        title: config.PageMenu.WillsEstate.title,
        content: config.PageMenu.WillsEstate.content,
        link:config.PageMenu.WillsEstate.link
    },
  
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Criminal Law'}
]

const FamilyLaw = () => {
    return (
        <Fragment>
            <header className="headerArea">
            <HeaderBotton className="headerBottomArea " />
                <HeaderTop className="headerTop" />
                {/* <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" /> */}
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Criminal Law"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Criminal Law"
                images={familyLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default FamilyLaw